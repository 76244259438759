import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SearchResultDTO } from 'leetify-shared-utils/dto';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	protected readonly recentSearchResultsSource = new Subject<SearchResultDTO[]>();
	public readonly recentSearchResults$ = this.recentSearchResultsSource.asObservable();
  private AuthService: AuthService;

	public constructor(
		protected readonly http: HttpClient,
	) {
	}

	public async search(query: string): Promise<SearchResultDTO[]> {
		try {
			return await this.http.post<SearchResultDTO[]>(`${environment.csApiUrl}/api/search/users`, { query }, { headers: AuthService.getHeaders() }).toPromise();
		} catch (err) {
			console.error(err);
			return [];
		}
	}

	public reloadRecentSearchResults(): void {
		this.http.get<SearchResultDTO[]>(`${environment.csApiUrl}/api/search/recent-search-results`, { headers: AuthService.getHeaders() }).subscribe((results) => this.recentSearchResultsSource.next(results));
	}

	public saveRecentSearchResult(result: SearchResultDTO): void {
		this.http.post<SearchResultDTO[]>(`${environment.csApiUrl}/api/search/recent-search-result/${result.userId}`, {}, { headers: AuthService.getHeaders() }).subscribe((results) => this.recentSearchResultsSource.next(results));
	}

	public removeRecentSearchResult(result: SearchResultDTO): void {
		this.http.delete<SearchResultDTO[]>(`${environment.csApiUrl}/api/search/recent-search-result/${result.userId}`, { headers: AuthService.getHeaders() }).subscribe((results) => this.recentSearchResultsSource.next(results));
	}
}
