import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { socialMediaLinks } from 'src/constants';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-topnav',
	templateUrl: './topnav.component.html',
	styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent implements OnChanges, OnDestroy, OnInit {
	protected readonly socialMediaLinks = socialMediaLinks;

	@Output() protected readonly toggleNavigation = new EventEmitter<boolean | void>();

	@Input() protected navigationActive = false;

	protected focusSearchInput: Subject<boolean> = new Subject<boolean>();
	protected focusSearchInput$ = this.focusSearchInput.asObservable();
	protected searchActive = false;
	protected user: User;

	protected proButtonClass = 'btn-primary';
	protected proButtonText = 'Get Pro';

	protected readonly ngUnsubscribe = new Subject<void>();

	public constructor(
		// protected readonly clubsService: ClubsService,
		protected readonly userService: UserService,
	) {
		//
	}

  protected onLogin() {
    window.location.href = `${environment.csFrontendBaseUrl}/auth/login?home=1`;
  }

  protected onSignup() {
    window.location.href = `${environment.csFrontendBaseUrl}/auth/signup?home=1`;
  }

	public toggleNav(e: MouseEvent): void {
		e.preventDefault();
		this.toggleNavigation.emit();
	}

	public toggleSearch(e?: MouseEvent, value?: boolean): void {
		if (e) e.preventDefault();

		this.searchActive = value === undefined
			? !this.searchActive
			: value;

		if (this.searchActive) {
			document.body.classList.add('--mobile-search-active');
			this.toggleNavigation.emit(false);
			this.focusSearchInput.next(true);
		} else {
			document.body.classList.remove('--mobile-search-active');
		}
	}

	private setProButton(user: User): void {
		if (!user) return;

		this.proButtonClass = 'btn-primary';
		this.proButtonText = 'Get Pro';

		if (user.isProPlan) {
			this.proButtonClass = 'btn-primary';
			this.proButtonText = 'My Pro';
		}

		if (user.isCollector) {
			this.proButtonClass = 'btn-founder';
		}
	}

	public setUser(user: User): void {
		this.user = user;

		this.setProButton(user);
	}

  protected goToPro() {
    window.location.href = `${environment.csFrontendBaseUrl}/app/pro`;
  }

  protected goToProfile() {
    window.location.href = `${environment.csFrontendBaseUrl}/app/profile`;
  }

	public ngOnInit(): void {
    this.userService.reloadUser();
    this.setUser(this.userService.user);
    this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.setUser(user));

    /*this.clubsService.selectedClub$.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((selectedClub: ClubListItemInfo) => this.selectedClub = selectedClub);*/
  }

	public ngOnChanges(changes: SimpleChanges): void {
		// close mobile search when activating mobile nav
		if (changes.navigationActive && changes.navigationActive.currentValue) this.searchActive = false;
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
