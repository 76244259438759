<nav>
	<ul class="sidebar-menu">
		<li>
			<a routerLink="/" routerLinkActive="active" queryParamsHandling="merge"
				[routerLinkActiveOptions]="{ exact: true }" class="cursor-pointer">
				<i class="leet-dashboard sidebar-icon"></i>
				Home
			</a>
		</li>

		<li>
			<a class="cursor-pointer" (click)="handleRoutes('/app/matches')">
				<i class="leet-matches sidebar-icon"></i>
				Matches
			</a>
		</li>

		<li>
			<a class="cursor-pointer" (click)="handleRoutes('/app/clubs')">
				<leetify-icon [icon]="Icon.CLUB" class="sidebar-icon"></leetify-icon>
				My Clubs
			</a>
		</li>

		<hr>

		<li>
			<a class="cursor-pointer" (click)="handleRoutes('/app/general')">
				<i class="fas fa-table sidebar-icon"></i>
				General
			</a>
		</li>

		<li>
			<a class="cursor-pointer" (click)="handleRoutes('/app/focus-areas')">
				<i class="leet-focus-areas sidebar-icon"></i>
				Focus Areas
			</a>
		</li>

		<li>
			<a class="cursor-pointer" (click)="handleRoutes('/app/maps')">
				<i class="leet-maps sidebar-icon"></i>
				Maps
			</a>
		</li>

		<li>
			<a class="cursor-pointer" (click)="handleRoutes('/app/aim')">
				<i class="leet-aim sidebar-icon"></i>
				Aim
			</a>
		</li>

		<li>
			<a class="cursor-pointer" (click)="handleRoutes('/app/utility')">
				<i class="leet-utility sidebar-icon"></i>
				Utility
			</a>
		</li>

		<hr>

		<li>
			<a class="cursor-pointer" (click)="handleRoutes('/app/servers')">
				<i class="fas fa-server sidebar-icon"></i>
				Practice Servers
			</a>
		</li>

		<hr>

		<li>
			<a class="justify-content-between pr-2 cursor-pointer" (click)="handleRoutes('/app/referrals')">
				<span>
					<img class="sidebar-icon pro-icon" src="assets/leetify/leetify-logo-pro.svg" alt="Leetify">
					Free Pro
				</span>
			</a>
		</li>
	</ul>

	<div class="sidebar-social">
		<a
			[queryParams]="{ redirect: 'no' }"
			aria-label="Home Page"
			container="body"
			ngbTooltip="Go to Home Page"
			openDelay="300"
			routerLink="/"
			tooltipClass="default-tooltip"
		>
			<leetify-icon [icon]="Icon.HOME"></leetify-icon>
		</a>

		<a *ngFor="let social of socialMediaLinks" [href]="social.link" target="_blank" [attr.aria-label]="social.title">
			<leetify-icon [icon]="social.icon"></leetify-icon>
		</a>
	</div>
</nav>
