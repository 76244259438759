<div #inputContainer class="search-box" [ngClass]="{ 'results-open': isSearchOpen }">
	<input
		#input
		type="text"
		class="form-control"
		placeholder="Player comparison - Esportal/Faceit/Steam nickname, Steam id or Steam profile link"
		(focus)="searchFocused()"
		[(ngModel)]="searchTerm"
	/>

	<span class="clear-search-button" (click)="clearSearch()" *ngIf="searchTerm">
		<i class="fas fa-times"></i>
	</span>
</div>

<div #resultsContainer class="search-results-container" [ngClass]="{ 'results-open': isSearchOpen }">
	<div *ngIf="user" class="search-results">
		<div class="heading">
			Recent
		</div>

		<div *ngIf="recentSearchResults.length < 1" class="result-row --non-interactible">
			No Recent Searches
		</div>

		<div *ngFor="let result of recentSearchResults" class="result-row">
			<div class="text-truncate">
				<img class="rounded-circle" [src]="result.pictureUrl || 'assets/images/anon.png'" alt="picture"/>
				{{ result.nickname }}
			</div>

			<div class="user-actions">
				<button class="action btn icon-action-button" (click)="spectate(result)" [disabled]="result.spectateDisabled" [ngbTooltip]="result.spectateTooltip" openDelay="300" tooltipClass="default-tooltip" container="body">
					<i *ngIf="!result.spectateDisabled" class="fa fa-eye"></i>
					<i *ngIf="result.spectateDisabled" class="fa fa-eye-slash"></i>
				</button>

				<a class="action btn icon-action-button" (click)="goToCompare(result)" ngbTooltip="Compare" openDelay="300" tooltipClass="default-tooltip" container="body" (click)="saveRecentResult(result)">
					<i class="fa fa-exchange-alt"></i>
				</a>

				<a (click)="goToProfile(result)" class="action btn btn-primary" (click)="saveRecentResult(result)">
					Profile
				</a>

				<span class="remove-friend-button" title="Remove from Recent" aria-label="Remove from Recent" (click)="removeRecentResult(result)">
					<i class="fas fa-times"></i>
				</span>
			</div>
		</div>

		<ng-container *ngIf="searchTerm">
			<div class="heading">
				Search Results
				<img src="assets/images/spinner.svg" class="spinner" *ngIf="requestInProgress" alt="spinner"/>
			</div>

			<div *ngIf="!requestInProgress && searchResults.length < 1" class="result-row --non-interactible">
				No Search Results
			</div>

			<div *ngFor="let result of searchResults" class="result-row">
				<div class="text-truncate">
					<img class="rounded-circle" [src]="result.pictureUrl || '/assets/images/anon.png'" />
					{{ result.nickname }}
					<div
						*ngIf="result.verifiedPro"
						ngbTooltip="This is the real {{ result.nickname }}"
						tooltipClass="default-tooltip --dark"
						class="badge badge-dark ml-1 px-1"
						style="vertical-align:1px"
					>
						<i class="mdi mdi-check-bold mr-1"></i>
						Pro Player
					</div>
				</div>

				<div class="user-actions">
					<button
						class="action btn icon-action-button"
						(click)="spectate(result)" [disabled]="result.spectateDisabled" [ngbTooltip]="result.spectateTooltip" openDelay="300" tooltipClass="default-tooltip" container="body" (click)="saveRecentResult(result)">
						<i *ngIf="!result.spectateDisabled" class="fa fa-eye"></i>
						<i *ngIf="result.spectateDisabled" class="fa fa-eye-slash"></i>
					</button>

					<a class="action btn icon-action-button" (click)="goToCompare(result)" ngbTooltip="Compare" openDelay="300" tooltipClass="default-tooltip" container="body" (click)="saveRecentResult(result)">
						<i class="fa fa-exchange-alt"></i>
					</a>

					<a (click)="goToProfile(result)" class="action btn btn-primary" (click)="saveRecentResult(result)">
						Profile
					</a>
				</div>
			</div>
		</ng-container>
	</div>

	<div *ngIf="!user" class="authenticate-cta">
		Please
		<a class="cursor-pointer text" (click)="goToLogin()">log in</a>
		or
		<a class="cursor-pointer text" (click)="goToSignup()">sign up</a>
		to search for players.
	</div>
</div>
