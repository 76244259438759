import { AuthService } from 'src/app/auth/auth.service';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { ProgressReportQuarter } from 'leetify-shared-utils/enums';
import { Router, Scroll } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { ProgressReportsHelper } from 'src/app/helpers/progress-reports.helper';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { environment } from '../../../../environments/environment';

interface AvailableProgressReportViewModel {
	link: (string | number)[];
	title: string;
}

interface AvailableProgressReportsYearViewModel {
	heading: string | number;
	reports: AvailableProgressReportViewModel[];
}

@Component({
	selector: 'app-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	host: {
		'(document:click)': 'onClick($event)',
	},
})
export class UserMenuComponent implements OnDestroy, OnInit {
	protected readonly Icon = Icon;
	protected readonly ngUnsubscribe = new Subject<void>();

	protected availableAnnualRecaps: AvailableProgressReportViewModel[];
	protected availableProgressReports: AvailableProgressReportsYearViewModel[];
	protected isAnnualRecapsSubmenuExpanded = false;
	protected isMenuActive = false;
	protected isProgressReportsSubmenuExpanded = false;
	protected user: User;

	public constructor(
		protected readonly _eref: ElementRef,
		protected readonly authService: AuthService,
		protected readonly router: Router,
		protected readonly userService: UserService,
	) {
		//
	}

	protected onClick(e: PointerEvent): void {
		if (!this._eref.nativeElement.contains(e.target)) { // or some similar check
			this.toggleDropdown(false);
		}
	}

	protected toggleDropdown(isActive = !this.isMenuActive): void {
		this.isMenuActive = isActive;

		if (!isActive) {
			this.toggleAnnualRecapsSubmenu(false);
			this.toggleProgressReportsSubmenu(false);
		}
	}

	protected toggleAnnualRecapsSubmenu(isExpanded = !this.isAnnualRecapsSubmenuExpanded): void {
		this.isAnnualRecapsSubmenuExpanded = isExpanded;
	}

	protected toggleProgressReportsSubmenu(isExpanded = !this.isProgressReportsSubmenuExpanded): void {
		this.isProgressReportsSubmenuExpanded = isExpanded;
	}

	protected logout(): void {
		this.authService.logout();
		window.location.href = '/';
	}

	protected handleUser(user: User): void {
		this.user = user;
		this.setAvailableProgressReports();
	}

  public goToPage(location: string) {
    window.location.href = `${environment.csFrontendBaseUrl}/app/${location}`;
  }

  protected goToProfile() {
    window.location.href = `${environment.csFrontendBaseUrl}/app/profile/${this.user.steam64Id || ''}`;
  }

	protected setAvailableProgressReports(): void {
		const reports = ProgressReportsHelper.getAvailableProgressReports({
			steam64Id: this.user.steam64Id,
			userCreatedAt: new Date(this.user.signUpDate),
			userId: this.user.id,
		});

		const annualRecaps: AvailableProgressReportViewModel[] = [];
		const progressReports: AvailableProgressReportsYearViewModel[] = [];

		for (const report of reports) {
			const reportViewModel: AvailableProgressReportViewModel = {
				link: ProgressReportsHelper.getUrlSegments(report),
				title: ProgressReportsHelper.getProgressReportTitleWithoutNickname(report.year, report.quarter),
			};

			if (report.quarter === ProgressReportQuarter.ANNUAL) {
				annualRecaps.push(reportViewModel);
			} else {
				if (!progressReports.length || progressReports[progressReports.length - 1].heading !== report.year) {
					progressReports.push({ heading: report.year, reports: [] });
				}

				progressReports[progressReports.length - 1].reports.push(reportViewModel);
			}
		}

		this.availableAnnualRecaps = annualRecaps;
		this.availableProgressReports = progressReports;
	}

	public ngOnInit(): void {
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));
		this.handleUser(this.userService.user);

		// close menu after clicking any link
		this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(() => this.toggleDropdown(false));
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
