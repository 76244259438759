import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { socialMediaLinks } from 'src/constants';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnDestroy, OnInit {
	protected readonly Icon = Icon;
	protected readonly isBrowser: boolean;
	protected readonly ngUnsubscribe = new Subject<void>();
	protected readonly socialMediaLinks = socialMediaLinks;

	protected user: User;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		private readonly authService: AuthService,
		private readonly userService: UserService,
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

  protected handleRoutes(route: string) {
    window.location.href = `${environment.csFrontendBaseUrl}${route}`;
  }

	private handleUser(user: User): void {
		this.user = user;
	}

	protected logout(): void {
		this.authService.logout();
		window.location.href = '/';
	}

	public ngOnInit(): void {
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));

		this.handleUser(this.userService.user);
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
