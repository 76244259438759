import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_BASE_HREF, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { filter } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import moment from 'moment';
import { NgbNavModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { Router, Scroll } from '@angular/router';

import { AppComponent } from 'src/app/app.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AtomsModule } from 'src/components/atoms/atoms.module';
import { SharedModule } from './shared.module';
import { PlainLayoutComponent } from './layouts/plain-layout/plain-layout.component';

@NgModule({
  declarations: [
    AppComponent,
    PlainLayoutComponent,
  ],

  imports: [
    AppRoutingModule,
    AtomsModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    // JwtModule.forRoot({ config: { tokenGetter: AuthService.getToken, allowedDomains: [environment.apiUrl.split('://').pop()] } }),
    NgbNavModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    NgSelectModule,
    ReactiveFormsModule,
    SharedModule,
  ],

  providers: [
    { provide: APP_BASE_HREF, useValue: '/home' },
    // AuthResolver,
    CurrencyPipe,
    Title,
    TitleCasePipe,
    /*{
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },*/
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor(router: Router) {
    router.events.pipe(
      filter((e): e is Scroll => e instanceof Scroll),
    ).subscribe(() => {
      if (typeof document === 'undefined') return;
      const $layoutContent = document.querySelector('main');
      if ($layoutContent) $layoutContent.scrollIntoView();
    });

    moment.updateLocale('en', { week: { dow: 1 } }); // tells moment that weeks start on monday across the entire app
  }
}
