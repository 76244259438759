<aside>
	<div class="mobile-nav-toggle">
		<button aria-label="Toggle Menu" title="Toggle Menu" (click)="toggleNav($event)">
			<i class="mdi {{ navigationActive ? 'mdi-close' : 'mdi-menu' }}"></i>
		</button>

		<a routerLink='/'>
			<img class="desktop" src="assets/images/leetify-logo-primary-white.svg" alt="Leetify">
			<img class="mobile" src="assets/leetify/leetify-icon.svg" alt="Leetify">
		</a>
	</div>

	<app-player-search
		[focusSearchInput$]="focusSearchInput$"
		[ngClass]="{ '--active': searchActive }"
		(close)="toggleSearch(null, false)"
	></app-player-search>

	<div *ngIf="user" class="right">
		<button class="mobile-search-toggle" aria-label="Toggle Search" title="Toggle Search" (click)="toggleSearch($event)">
			<i class="mdi {{ searchActive ? 'mdi-close' : 'mdi-magnify' }}"></i>
		</button>

		<div class="pro">
			<a
				(click)="goToPro()"
				class="btn"
				[ngClass]="proButtonClass"
				container="body"
				[ngbTooltip]="user.isCollector ? 'Thanks for being a Founder!' : null"
				openDelay="600"
				placement="bottom"
				tooltipClass="default-tooltip"
			>
				{{ proButtonText }}
			</a>
		</div>

		<!--<app-import-status-menu></app-import-status-menu>-->
		<app-user-menu></app-user-menu>

		<a (click)="goToProfile()" class="user-account-settings-link">
			<img [src]="user.steamAvatarUrl || 'assets/images/anon.png'" alt="" class="rounded-circle">
		</a>
	</div>

	<div *ngIf="!user" class="right --unauthenticated">
		<div class="socials">
			<a *ngFor="let social of socialMediaLinks" [href]="social.link" target="_blank" [attr.aria-label]="social.title">
				<leetify-icon [icon]="social.icon"></leetify-icon>
			</a>
		</div>

		<div class="spacer"></div>

		<div class="auth-buttons">
			<a (click)="onLogin()" class="login-button cursor-pointer">Log In</a>
			<a (click)="onSignup()" class="signup-button">Sign Up</a>
		</div>
	</div>
</aside>
