import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {filter} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import {Router, Scroll} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})

export class AppLayoutComponent implements OnDestroy, OnInit {
  public navigationActive = false;

  protected readonly isBrowser: boolean;
  protected readonly ngUnsubscribe = new Subject<void>();

  public constructor(
    @Inject(PLATFORM_ID) platformId: Record<string, any>,
    protected readonly router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  protected toggleNavigation(value?: boolean): void {
    this.navigationActive = value === undefined
      ? !this.navigationActive
      : value;
  }

  protected onMainContentClick(e: MouseEvent): void {
    if (!this.navigationActive) return;

    e.preventDefault();
    e.stopPropagation();
    this.navigationActive = false;
  }

  public ngOnInit(): void {
    // prevents tooltips near corners sometimes causing two vertical scrollbars + a horizontal scrollbar -- not great though
    if (typeof document !== 'undefined') document.querySelector('body').classList.add('--overflow-hidden');


    // close mobile nav after clicking any link
    this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(() => this.navigationActive = false);
  }

  public ngOnDestroy(): void {
    if (typeof document !== 'undefined') document.querySelector('body').classList.remove('--overflow-hidden');

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
