<!-- routerLink doesn't work on the fallback route (apparently) -->
<a href="/" class="logo">
	<img src="assets/images/leetify-logo-primary-white.svg" alt="Leetify">
</a>

<h1>Not found :(</h1>

<p>
	We couldn't find the page you were looking for.
	<br>
	<a href="/">Go back to the home page.</a>
</p>

<p>
	If you need help, <a (click)="goToSupport()" class="cursor-pointer pink-text">let us know.</a>
</p>
