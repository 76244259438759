export enum ProgressReportError {
	GENERIC = 'generic',
	INACCESIBLE_DUE_TO_PRIVACY_SETTINGS = 'inaccesible_due_to_privacy_settings',
}

export enum ProgressReportQuarter {
	ANNUAL = 'annual', // the entire year

	H1 = 'h1', // first half of the year (i.e. January-June)
	H2 = 'h2', // second half of the year (i.e. July-December)

	Q1 = 'q1', // first quarter of the year (i.e. January-March)
	Q2 = 'q2', // second quarter of the year (i.e. April-June)
	Q3 = 'q3', // third quarter of the year (i.e. July-September)
	Q4 = 'q4', // fourth quarter of the year (i.e. October-December)
}
