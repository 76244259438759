import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/models/user.model';
import { environment } from '../../environments/environment';

export {
	User,
};

@Injectable({
	providedIn: 'root',
})
export class UserService {
	public user$ = new Subject<any>();
	public user: User;

	private readonly isBrowser: boolean;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		private http: HttpClient,
	) {
		this.isBrowser = isPlatformBrowser(platformId);

    this.reloadUser();
		this.user$.subscribe((user) => (this.user = user));
	}

	public reloadUser(): any {
		if (!this.isBrowser) return this.user$.next(null);

    const token = AuthService.getToken();
    const headers=  new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    return this.http.get<User>(`${environment.apiUrl}/api/user/v1/userinfo`, { headers }).subscribe(
			(user: any) => {
				this.user$.next(user);
				return user;
			},
			(e) => {
        console.log(e);
				if (e.status === 403) AuthService.removeToken();

				window.location.href = `${environment.csFrontendBaseUrl}/auth`;
				return null;
			},
		);
	}
}
