import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HighlightDTO } from '../../../leetify-home-dtos/homepage-dto';

@Pipe({
	name: 'highlightIframeUrl',
})
export class HighlightIframeUrlPipe implements PipeTransform {
	public constructor(
		protected readonly domSanitizer: DomSanitizer,
	) {
		//
	}

	public transform(highlight: HighlightDTO, autoplay = false, background = false): SafeResourceUrl {
		// return a fully black SVG if no highlight was passed
		if (!highlight?.url) return this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml,${encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1" style="background:#000"></svg>')}`);

		const url = new URL(highlight.url);

		/*if (this.userService.user?.steam64Id) url.searchParams.set('uid', this.userService.user.steam64Id);
		else url.searchParams.delete('uid');
*/
		if (autoplay) {
			url.searchParams.set('autoplay', 'true');
			url.searchParams.set('muted', 'false');
		} else {
			url.searchParams.delete('autoplay');
			url.searchParams.delete('muted');
		}

		if (background) url.searchParams.set('background', 'true');
		else url.searchParams.delete('background');

		return this.domSanitizer.bypassSecurityTrustResourceUrl(url.toString());
	}
}
