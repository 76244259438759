import { CanActivate, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate{
  public constructor(
    private readonly authService: AuthService,
  ) {
    //
  }

  public async canActivate(): Promise<boolean | UrlTree> {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    console.log('auth guard - not authenticated');
    window.location.href = `${environment.csFrontendBaseUrl}/auth/login`;
    return false;
  }
}
