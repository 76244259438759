import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';

export type SpectatingUser = {
	avatarUrl: string;
	name: string;
	steam64Id: string;
}

@Injectable({
	providedIn: 'root',
})
export class SpectatingService {
	public isSpectating: boolean;

	private spectatingId: string;
	private spectatingUser: SpectatingUser;

	private spectatingUserSource = new Subject<SpectatingUser>();
	private spectatingIdSource = new Subject<string>();

	public spectatingId$ = this.spectatingIdSource.asObservable();
	public spectatingUser$ = this.spectatingUserSource.asObservable();

	public constructor(
		private readonly activeRoute: ActivatedRoute,
		private readonly http: HttpClient,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
    private readonly authService: AuthService,
	) {
		this.activeRoute.queryParams.subscribe(() => {
			this.loadSpectatingId();
		});
	}

	public getSpectatingUser(): SpectatingUser {
		return this.spectatingUser;
	}

	public getSpectatingId(): string {
		if (!this.spectatingId) {
			this.loadSpectatingId();
		}
		return this.spectatingId;
	}

	public loadSpectatingId(): void {
		const spectatingId = this.getSpectatingIdFromParams();
		if (spectatingId === this.spectatingId) {
			return;
		}

		this.spectatingId = spectatingId;
		this.spectatingIdSource.next(spectatingId);
		this.fetchSpectatingUser(spectatingId);
	}

	public fetchSpectatingUser(spectatingId: string): void {
		if (!spectatingId) {
			this.isSpectating = false;
			this.spectatingUser = null;
			this.spectatingUserSource.next(null);
			return;
		}

		try {
			this.http.get<SpectatingUser>(`${environment.csApiUrl}/api/user/spectating/${spectatingId}`, { headers: AuthService.getHeaders() }).subscribe((user: SpectatingUser) => {
				this.isSpectating = !!user;
				this.spectatingUser = user;
				this.spectatingUserSource.next(user);
			});
		} catch (e) {
			this.isSpectating = false;
			this.spectatingUser = null;
			this.spectatingUserSource.next(null);
		}
	}

	public startSpectating(userId: string): void {
		return this.redirectToSpectating(userId, ['/app/general']);
	}

	public redirectToSpectating(userId: string, url: string[]): void {
    window.location.href = `${environment.csFrontendBaseUrl}/app/general?spectating=${encodeURIComponent(userId)}`;
	}

	private getSpectatingIdFromParams(): string {
		return this.route.snapshot.queryParams['spectating'];
	}
}
