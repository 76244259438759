import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { ButtonComponent } from 'src/components/atoms/button/button.component';
import { CardBodyInnerDirective } from 'src/components/atoms/card/card-body-inner.directive';
import { CardBodyOuterDirective } from 'src/components/atoms/card/card-body-outer.directive';
import { CardComponent } from 'src/components/atoms/card/card.component';
import { CardHeaderInnerDirective } from 'src/components/atoms/card/card-header-inner.directive';
import { CardHeaderOuterDirective } from 'src/components/atoms/card/card-header-outer.directive';
import { CardHeaderTextDirective } from 'src/components/atoms/card/card-header-text.directive';
import { ExpandableVerticalComponent } from 'src/components/atoms/expandable-vertical/expandable-vertical.component';
import { IconComponent } from 'src/components/atoms/icon/icon.component';
import { RadioComponent } from 'src/components/atoms/radio/radio.component';

const components = [
	// Directives (also not technically atoms, but they also fall into the same category as atoms)
	CardBodyInnerDirective,
	CardBodyOuterDirective,
	CardHeaderInnerDirective,
	CardHeaderOuterDirective,
	CardHeaderTextDirective,

	// Atoms
	ButtonComponent,
  CardComponent,
  ExpandableVerticalComponent,
  IconComponent,
  RadioComponent,
];

@NgModule({
	declarations: [
		...components,
	],

	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
	],

	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		...components,
	],

	providers: [
		Title,
	],
})
export class AtomsModule {
	//
}
