<a [routerLink]="'/'" class="sidebar-logo">
  <img src="assets/images/leetify-logo-primary-white.svg" alt="Leetify">
</a>

<app-topnav [navigationActive]="navigationActive" (toggleNavigation)="toggleNavigation($event)"></app-topnav>

<app-nav [ngClass]="{ '--active': navigationActive }"></app-nav>

<main class="container-fluid" [ngClass]="{ '--muted': navigationActive }">
  <router-outlet></router-outlet>

  <div class="mobile-nav-darken-overlay" [ngClass]="{ '--active': navigationActive }" (click)="onMainContentClick($event)"></div>
</main>
