import { Icon } from './components/atoms/icon/icon.component';

// benchmarks
export const GREAT = '#4dc49e';
export const GOOD = '#9ccc72';
export const AVERAGE = '#dedede';
export const SUBPAR = '#f7cd60';
export const POOR = '#f77c60';

// brand
export const LEETIFY_FOUNDER_BLUE = '#4c49f8';
export const LEETIFY_PINK = '#f84982';
export const LEETIFY_PURPLE = '#7547b6';

// misc
export const socialMediaLinks: { icon: Icon; link: string; title: string }[] = [
  { icon: Icon.STEAM, link: 'https://steamcommunity.com/groups/Leetify', title: 'Steam' },
  { icon: Icon.IM_STILL_GONNA_CALL_IT_TWITTER, link: 'https://twitter.com/leetify', title: 'Twitter' },
  { icon: Icon.DISCORD, link: 'https://discord.gg/UNygC8BAVg', title: 'Discord' },
  { icon: Icon.EMAIL, link: 'mailto:support@leetify.com', title: 'Email' },
  { icon: Icon.YOUTUBE, link: 'https://www.youtube.com/leetify', title: 'YouTube' },
];

export const S3_BASE_URL = 'http://leetify-lol-assets.auth-3019c2b156144fe3af863840d5f127be.storage.gra.cloud.ovh.net';
