import { NgbNavModule, NgbProgressbarModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { TitleCasePipe } from '@angular/common';

import { AtomsModule } from 'src/components/atoms/atoms.module';

import { AppLayoutComponent } from 'src/app/layouts/app-layout/app-layout.component';
import { HighlightIframeUrlPipe } from './pipes/highlight-iframe-url.pipe';
import { NavComponent } from './layout/nav/nav.component';
import { PlayerSearchComponent } from './layout/topnav/player-search/player-search.component';
import { TopnavComponent } from './layout/topnav/topnav.component';
import { UserMenuComponent } from './layout/topnav/user-menu/user-menu.component';
import { AttemptingLoginComponent } from './layout/attempting-login/attempting-login.component';

const components = [
  AttemptingLoginComponent,
  AppLayoutComponent,
  HighlightIframeUrlPipe,
  NavComponent,
  PlayerSearchComponent,
  TopnavComponent,
  UserMenuComponent,
];

@NgModule({
  declarations: [...components],

  imports: [AtomsModule, NgbNavModule, NgbProgressbarModule, NgSelectModule, NgbTooltip],

  exports: [AtomsModule, NgbNavModule, NgbProgressbarModule, NgSelectModule, ...components],

  providers: [TitleCasePipe],
})
export class SharedModule {
  public constructor() {
  }
}
