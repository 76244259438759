import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { AuthGuard } from './auth/auth.guard';
import { PlainLayoutComponent } from './layouts/plain-layout/plain-layout.component';
import { ErrorNotFoundComponent } from './pages/error/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],

    children: [
      { path: '', loadChildren: () => import('src/app/pages/user/feed/feed.module').then((m) => m.FeedModule) },
    ],
  },

  // errors
  {
    path: '',
    component: PlainLayoutComponent,
    children: [
      { path: '**', component: ErrorNotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  //
}
