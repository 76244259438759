import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class MetaService {
	public constructor(
		protected readonly meta: Meta,
		protected readonly title: Title,
	) {
		//
	}

	public setTitle(title: string | null): MetaService {
		if (title) {
			this.title.setTitle(`${title} – Leetify`);
			this.meta.updateTag({ property: 'og:title', content: title });
		} else {
			this.title.setTitle(`Leetify`);
			this.meta.updateTag({ property: 'og:title', content: 'Leetify' });
		}

		return this;
	}

	public setDescription(description: string | null): MetaService {
		if (!description) {
			description = 'Compare your performance to your friends with detailed match reports, aim and utility ratings, highlights, and more.';
		}

		this.meta.updateTag({ name: 'description', content: description });

		return this;
	}

	public setPreviewImage(url: string | null): MetaService {
		if (url) {
			this.meta.updateTag({ property: 'og:image', content: url });
			this.meta.updateTag({ property: 'twitter:card', content: 'summary_large_image' });
		} else {
			this.meta.updateTag({ property: 'og:image', content: 'assets/images/og.jpg' });
			this.meta.updateTag({ property: 'twitter:card', content: '' });
		}

		return this;
	}

	public reset(): MetaService {
		return this.setDescription(null)
			.setTitle(null)
			.setPreviewImage(null);
	}
}
